import React from 'react';
import Helmet from "react-helmet";

import { ThemeContext } from '../../context/theme-context';
                                                          
import Layout from '../../components/layout';
import Footer from '../../components/footer';
import Title from '../../components/title';
import BannerImage from '../../components/bannerImage';
import ContactBlock from '../../components/contactBlock';
import NextCase from '../../components/nextCase';
import IntroBlock from '../../components/introBlock';
import BannerResult from '../../components/bannerResult';
import SimplePhrase from '../../components/simplePhrase';
import ChromaticPalette from '../../components/chromaticPalette';

const CasesPage = ({ location }) => {

    return (
      <Layout bg="white" location={location}>
        <Helmet>
          <title>ATUO - Cases - Átimo</title>
        </Helmet>

        <div className="container mx-auto">
          <Title
            title="Átimo"
            titleColor="#f70b3e"
            description="Liberdade para viver os melhores momentos da vida."
            smallDescription="[Autoridade, digital, ágil]"
          />

          <BannerImage src={`/atimo/atimo_content_one.jpg`} title="Átimo" />

          <IntroBlock
            logo={`/atimo/atimo_logo.svg`}
            alt="Átimo"
            description="Modernizar a marca, criada há mais de 40 anos no interior de São Paulo com o nome de EPE Despachante, se posicionar como marca conectada e com essa reformulação abranger um novo público e expandir a carteira de clientes."
            list={[
              "Estratégia & Inovacão",
              "Pesquisa & Inteligência",
              "Design de Experiência do Consumidor",
              "Cultura, Conteúdo & Social",
            ]}
          />

          <SimplePhrase paddingX={24}>
            Posicionar uma marca de atitude conectando-a com seu público diverso
            e inspirador.
          </SimplePhrase>

          <div className="flex justify-center gap-x-16 md:px-24 items-center mt-24 mb-40 md:my-60 flex-col md:flex-row">
            <div className="md:w-1/2 text-2xl order-2 md:order-1 mt-24 md:mt-0">
              <p>
                O novo nome, Átimo, está relacionado à agilidade, ao movimento,
                algo que acontece rapidamente em segundos.
              </p>
            </div>

            <div className="md:w-1/2 order-1 md:order-2 text-center">
              <img
                src={`/atimo/atimo_stamp_1.svg`}
                alt="Atimo"
                className="inline"
              />
              <img
                src={`/atimo/atimo_stamp_2.svg`}
                alt="Atimo"
                className="inline"
              />
            </div>
          </div>

          <ChromaticPalette
            description={
              "As cores foram inspiradas nos tons de fabricação de carros. O vermelho como cor principal transmite autoridade, confiança e intensidade, resgatando conceitos inconscientes."
            }
          >
            <div className="py-24 md:py-16 flex flex-wrap gap-y-10 gap-x-9 md:justify-between">
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-mirror="false"
                style={{ backgroundColor: "#f70b3e" }}
              >
                #f70b3e
              </div>
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-mirror="false"
                style={{ backgroundColor: "#2d2c3a" }}
              >
                #2d2c3a
              </div>
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-mirror="false"
                style={{ backgroundColor: "#ffffff", color: "#2d2c3a" }}
              >
                #ffffff
              </div>
            </div>
          </ChromaticPalette>

          <div className="my-40 md:my-60 md:px-28 flex flex-col-reverse md:flex-row gap-24 md:gap-10 items-center">
            <div className="md:w-1/2 text-left">
              <img
                src={`/atimo/atimo_symbol.svg`}
                alt="Atimo"
                className="inline"
              />
            </div>
            <div className="md:w-1/2 text-2xl md:text-4xl md:leading-normal font-semibold">
              O símbolo com a inicial <strong>“á”</strong> pode ser usado de
              forma isolada como ícone da marca em redes sociais e outros
              materiais de comunicação.
            </div>
          </div>

          <div className="flex items-center flex-col md:flex-row mt-40 gap-x-16">
            <div className="w-full md:w-2/4">
              <p className="text-2xl md:text-4xl font-semibold md:leading-normal mb-10 md:mb-8">
                Mobilidade, diversidade, atitude e liberdade inspiram o novo
                momento da marca.
              </p>
              <p className="text-2xl mb-24 md:mb-0">
                A comunicação deve ter foco nas pessoas e nos bons momentos. A
                presença de veículos nas peças deve estar sempre nos detalhes,
                reforçando o conceito dos momentos.
              </p>
            </div>

            <div className="w-full">
              <img
                src={`/atimo/atimo_content_two.jpg`}
                alt="Code and Soul"
                className="inline"
              />
            </div>
          </div>

          <SimplePhrase paddingX={24}>
            Acreditamos que as pessoas são diferentes e, por isso, precisam de
            soluções personalizadas.{" "}
          </SimplePhrase>

          <div className="flex flex-col md:flex-row items-stretch mb-24 gap-y-10 md:gap-0 md:mb-16">
            <div>
              <img src={`/atimo/atimo_content_three.jpg`} alt="Átimo" />
            </div>

            <div className="flex flex-col md:flex-col gap-y-10 md:gap-0">
              <img
                className="h-full"
                src={`/atimo/atimo_content_four.png`}
                alt="Átimo"
              />
              <img
                className="h-full"
                src={`/atimo/atimo_content_five.png`}
                alt="Átimo"
              />
            </div>
          </div>

          <div className="text-2xl md:px-24 md:text-center mb-40 md:mb-60">
            <p>
              A expressão visual passa a mensagem de marca séria, mas moderna. É
              autoridade no assunto. Seu diferencial é oferecer qualidade e
              agilidade em seus serviços, essencial para a vida agitada no dia a
              dia.
            </p>
          </div>

          <div className="my-40 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-7">
            <p className="text-2xl md:text-4xl font-semibold leading-tight md:leading-normal">
              Na comunicação a Átimo expressa a crença de que cada pessoa é
              única, e por isso necessita de uma solução personalizada.
            </p>
            <p className="text-xl md:text-2xl md:mt-36">
              A nova expressão visual transmite a mensagem de seriedade da
              marca, mas agora com toques de modernidade e autoridade, que
              garantem qualidade e agilidade nos serviços prestados, sobretudo
              para quem tem a vida agitada.
            </p>
          </div>
        </div>

        <BannerResult
          bg="#FF0033"
          list={[
            "Experiência de marca",
            "Marca com propósito",
            "Maior número de clientes",
            "+ Presença digital",
            "Aumento da carteira de clientes",
          ]}
        />

        <NextCase
          link="/cases/firstoeat"
          title="Firstoeat"
          description="Porque comida boa pode ser a qualquer hora."
          image="/firstoeat-next.png"
        />

        <ThemeContext.Consumer>
          {(cx) => (
            <ContactBlock
              description="Gostou dos nossos cases? Tem muito mais de onde veio! Clique no botão ao lado e entre em contato conosco. Vamos dar o primeiro passo para sua marca ir além."
              weather={cx?.cookies?.weather}
            />
          )}
        </ThemeContext.Consumer>

        <Footer />
      </Layout>
    );
};

export default CasesPage;
